import * as React from 'react'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Title from './Title'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { IconButton, Paper, TableContainer } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default function Salidas({ rows }) {
  const nav = useNavigate()
  const preventDefault = event => {
    event.preventDefault()
    nav(`/reportes/salidas`)
  }

  const handleView = row => () => nav(`/solicitudes/view/${row.id_solicitud}`)
 
  return (
    <Paper sx={{ p: 2 }}>
      <Title>Salidas Recientes</Title>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell>Categoría</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Entregado a</TableCell>
              <TableCell>Procesa</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  {moment(row.sal_fecha).format('DD/MM/YYYY HH:mm')}
                </TableCell>
                <TableCell>{row.prod_sku}</TableCell>
                <TableCell>{row.prod_nombre}</TableCell>
                <TableCell>{row.cat_nombre}</TableCell>
                <TableCell>{row.sal_cant}</TableCell>
                <TableCell>
                  {row.sol_recibe}
                </TableCell>
                <TableCell>
                  {row.creador_nombre} {row.creador_pat} {row.creador_mat}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="view"
                    color="primary"
                    onClick={handleView(row)}>
                    <SearchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Link
        href={'/reportes/salidas'}
        color="primary"
        onClick={preventDefault}
        sx={{ mt: 3 }}>
        Ver más
      </Link>
    </Paper>
  )
}
